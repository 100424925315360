.group-operator + .query-builder {
  border-top: 1px solid #ccc;
}

.group-operator {
  display: block;
  text-transform: uppercase;
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: rgba(255, 255, 255, 0.4);
  font-weight: 700;
  color: var(--tblr-info);
  cursor: default;
}

.query-group > .btn-group {
  margin-top: 1em;
}

.query-group .query-group {
  margin-left: 0.7em;
  padding-left: 0.8em;
  border-left: 1px solid darkgray;
}

.query-group {

  margin-bottom: 1em;
}

.query-builder-item {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.query-builder-item.dragging {
  z-index: 10000;
  pointer-events: none;
}

.query-builder-item-grabber {
  content: " ";
  display: block;
  width: 10px;
  cursor: grab;
}

.query-builder-item-container {
  padding: 10px;
}

.query-builder-item:hover .query-builder-item-grabber, .query-builder-item.dragging .query-builder-item-grabber {
  background-color: var(--tblr-azure);
}

.query-builder-item-actions {
  float: right;
}

.query-builder-condition-name {
  font-weight: 700;
  font-size: 12pt;
  display: inline-block;
}
/*
.query-builder-item:before {
  content: "";
  border-bottom: 1px solid black;
  vertical-align: center;
  display: inline-block;
  width: 0.6em;
  margin-left: -0.8em;
  margin-right: 0.2em;

  position: relative;
  top: -0.5em;
}
*/
